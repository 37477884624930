import React, { useEffect, useRef, useState } from "react"
import SearchBar from "../../components/SearchBar"
import http from "../../plugins/http"
import Loading from "../../components/Loading"
import TablePagination from "../../components/TablePagination"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import ModalApproveOrDisapprove from "./ApproveOrDisapprove/Modal"
import ModalProductJustifications from "./Justification/Modal"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { BreadCrumb } from "primereact/breadcrumb"

const TableRowActions = ({
	product,
	fetchProducts,
}: { product: any; fetchProducts: any }) => {
	return (
		<div className='flex justify-start align-center gap-x-2'>
			<ModalApproveOrDisapprove
				product={product}
				fetchProducts={fetchProducts}
				className='w-1/2'
			/>
			<ModalProductJustifications product={product} className='w-1/2' />
		</div>
	)
}

function ProductsPage() {
	const [products, setProducts] = useState<any[]>([])
	const [meta, setMeta] = useState<any>({})
	const [loading, setLoading] = useState<boolean>(false)
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [searchKey, setSearchKey] = useState<string>("")
	const [selectedStatus, setSelectedStatus] = useState<any>(null)
	const [filters, setFilters] = useState<any>(null)
	const searchBarRef = useRef<any>(null)
	const status: any[] = [
		{ name: "Selecione um status", code: null },
		{ name: "Em espera", code: 1 },
		{ name: "Atendida", code: 2 },
		{ name: "Reprovado", code: 3 },
		{ name: "Desativado pelo usuário", code: 0 },
	]

	const productState: any = {
		1: "Em espera",
		2: "Atendida",
		3: "Reprovado",
		0: "Desativado pelo usuário",
	}

	async function fetchProducts({
		page = currentPage,
		perPage = 10,
		search,
		status,
	}: {
		page?: number
		perPage?: number
		search?: string
		status?: number | null
	} = {}) {
		try {
			setLoading(true)
			const searchData = search ? `&search=${search}` : ""
			const statusData =
				status !== null && status !== undefined ? `&status=${status}` : ""
			const response = await http.get(
				`v1/managers/products?perPage=${perPage}&page=${page}&orderBy=name&orderDir=asc&user_full=1${searchData}${statusData}`,
			)
			if (response.status !== 200) {
				return
			}
			setProducts(response.data.data)
			setMeta(response.data.meta)
			setCurrentPage(page)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	function clearFilter() {
		setSearchKey("")
		setSelectedStatus(null)
		setCurrentPage(1)
		resetSearchBar()
		fetchProducts({ status: null, page: 1 })
	}

	function searchProducts(val: string) {
		setCurrentPage(1)
		fetchProducts({
			search: val,
			status: selectedStatus?.code,
			page: 1,
		})
	}

	const handleStatusChange = (e: any) => {
		setSelectedStatus(e.value)
		setCurrentPage(1)
		fetchProducts({
			status: e.value?.code,
			search: searchKey,
			page: 1,
		})
	}

	function resetSearchBar() {
		if (searchBarRef.current) {
			searchBarRef.current.reset()
		}
	}

	useEffect(() => {
		fetchProducts()
	}, [])

	const statusTemplate = (rowData: any) => {
		const statusColors: any = {
			1: "text-yellow-500", // Em espera
			2: "text-[#28A745]", // Atendida
			3: "text-red-500", // Reprovado
		}

		return (
			<div>
				<p className={statusColors[rowData.status] || "text-gray-500"}>
					{productState[rowData.status] || "Desconhecido"}
				</p>
			</div>
		)
	}

	const dateTemplate = (rowData: any) => (
		<>
			{new Date(rowData.updated_at).toLocaleDateString("pt-BR", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
			})}
		</>
	)

	const actionTemplate = (rowData: any) => (
		<div>
			<TableRowActions product={rowData} fetchProducts={fetchProducts} />
		</div>
	)

	const items = [{ label: "Gerenciamento de produtos", url: "/products" }]
	const home = { icon: "pi pi-home", url: "/home" }

	return (
		<div className='p-10'>
			<BreadCrumb model={items} home={home} className='mb-4' />
			<div className='flex justify-between items-center w-full'>
				<div className='flex-grow'>
					<SearchBar
						ref={searchBarRef}
						onSearch={(val) => {
							searchProducts(val)
							setSearchKey(val)
						}}
						classname='w-full'
					/>
				</div>
				<Button
					type='button'
					icon='pi pi-filter-slash'
					label='Limpar filtros'
					outlined
					onClick={clearFilter}
				/>
				<div className='flex flex-col ml-4'>
					<Dropdown
						name='status'
						value={selectedStatus}
						options={status}
						onChange={handleStatusChange}
						optionLabel='name'
						className='w-full'
						placeholder='filtrar por situação'
					/>
				</div>
			</div>
			{loading && (
				<div className='h-36 grid place-content-center p-20'>
					<Loading color='#212529' size='w-10' />
				</div>
			)}
			{!loading && products.length === 0 && (
				<div className='h-36 grid place-content-center p-20'>
					<p>Nenhum produto encontrado</p>
				</div>
			)}
			{!loading && products.length > 0 && (
				<>
					<DataTable
						stripedRows
						value={products}
						className='mt-10 font-roboto text-sm'
						dataKey='id'
						filters={filters}
					>
						<Column body={actionTemplate} header='Ações' />
						<Column field='user_full.name' header='Vendedor' />
						<Column field='name' header='Cadeia produtiva' />
						<Column field='product_type.name' header='Tipo' />
						<Column field='product_subtype.name' header='Subtipo' />
						<Column body={dateTemplate} header='Recebido' />
						<Column field='user_full.email' header='Email' />
						<Column field='user_full.phone' header='Telefone' />
						<Column
							body={(row) => <span>{row?.state?.name}</span>}
							header='Estado'
						/>
						<Column
							body={(row) => <span>{row?.city?.name}</span>}
							header='Cidade'
						/>
						<Column body={statusTemplate} header='Situação' />
					</DataTable>
					<TablePagination
						meta={meta}
						onChange={(page) =>
							fetchProducts({
								search: searchKey,
								status: selectedStatus?.code,
								page: page.page,
							})
						}
					/>
				</>
			)}
		</div>
	)
}

export default ProductsPage
