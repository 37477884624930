import { Card } from "primereact/card"
import { Message } from "primereact/message"
import React, { useEffect } from "react"
import { fetchDashboadSales, fetchDashboadUsers } from "../../api/dashboard"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { set } from "date-fns"

const MainPanel: React.FC = () => {
	const [dashboardsUsers, setDashboardsUsers] = React.useState<any[]>([])
	const [dashboardsSalesByMonth, setDashboardsSalesByMonth] = React.useState<
		any[]
	>([])

	useEffect(() => {
		fetchDashboadUsers().then((data) => {
			if (data.status !== 200) return console.error(data.data)
			setDashboardsUsers(data.data)
		})
		fetchDashboadSales().then((data) => {
			if (data.status !== 200) return console.error(data.data)

			const salesByMonth = data.data.reduce((acc: any, item: any) => {
				const month = new Date(item.created_at).getMonth()
				const year = new Date(item.created_at).getFullYear()
				const key = `${month + 1}/${year}`
				if (!acc[key]) acc[key] = 0
				acc[key] += Number(item.total)
				return acc
			}, {})

			setDashboardsSalesByMonth(
				Object.keys(salesByMonth).map((key) => ({
					month: key,
					value: salesByMonth[key],
				})),
			)
		})
	}, [])

	return (
		<>
			{/* <Message className="w-full mb-4" severity="warn" text="Página em construção" /> */}
			<div className='w-full grid grid-cols-2 xl:grid-cols-4 gap-2'>
				<Card className='col-span-2'>
					<HighchartsReact
						highcharts={Highcharts}
						options={{
							chart: {
								type: "pie",
							},
							title: {
								text: "Usuários por tipo",
							},
							series: [
								{
									data: dashboardsUsers.map((item: any) => ({
										name: item.name,
										y: item.value,
									})),
								},
							],
						}}
					/>
				</Card>
				<Card className='col-span-2'>
					<HighchartsReact
						highcharts={Highcharts}
						options={{
							chart: {
								type: "column",
							},
							title: {
								text: "Vendas por mês",
							},
							xAxis: {
								categories: dashboardsSalesByMonth.map((item) => item.month),
							},
							yAxis: {
								title: {
									text: "Valor das Vendas (R$)",
								},
							},
							tooltip: {
								pointFormat: "R$ {point.y:.2f}", // Format tooltip with 2 decimal places
							},
							series: [
								{
									name: "Vendas",
									data: dashboardsSalesByMonth.map((item) =>
										parseFloat(item.value),
									), // Use parseFloat
								},
							],
						}}
					/>
				</Card>
			</div>
		</>
	)
}

export default MainPanel
